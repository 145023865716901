<template>
  <div
    class="feature-list-small"
    :class="themeColorClass"
  >
    <UiHeader
      v-if="header"
      class="feature-list-small__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <div
      v-if="itemList.length"
      class="feature-list-small__list-wrapper"
      :class="wrapperSizeClass"
    >
      <FeatureListItem
        v-for="item in itemList"
        :key="item.id"
        :item="item"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { UiHeader } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import type { UiCeProductBoxesProps } from '~ui/types'

import FeatureListItem from '../FeatureList/FeatureListItem.vue'

const props = defineProps<UiCeProductBoxesProps>()
const themeColorClass = useTheming(props.themeColor)

const wrapperSizeClass = computed(() => {
  const itemsAmount = props.itemList.length

  if (itemsAmount % 3 === 2) return 'feature-list-small__list-wrapper--3-2'

  if (itemsAmount % 2 === 0 && itemsAmount % 3 !== 0)
    return 'feature-list-small__list-wrapper--2'
  if (itemsAmount % 3 === 0) return 'feature-list-small__list-wrapper--3'

  if (itemsAmount % 2 === 1 && itemsAmount % 3 !== 1)
    return 'feature-list-small__list-wrapper--2-1'
  if (itemsAmount % 3 === 1) return 'feature-list-small__list-wrapper--3-1'

  return ''
})
</script>

<style lang="scss">
.feature-list-small {
  padding-top: rem(24px);

  &__list-wrapper {
    display: grid;
    gap: rem(20px);
    grid-template-columns: repeat(1, 1fr);

    &--2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &--3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &--2-1 {
      grid-template-columns: repeat(2, 1fr);

      & > *:last-child {
        grid-column: 1 / -1;
      }
    }

    &--3-1 {
      grid-template-columns: repeat(2, 1fr);

      & > *:last-child {
        grid-column: 1 / -1;
      }
    }

    &--3-2 {
      grid-template-columns: repeat(6, 1fr);

      & > * {
        grid-column: span 2;
      }

      & > *:nth-last-child(1),
      & > *:nth-last-child(2) {
        grid-column: span 3;
      }
    }

    @include media-query(max sm) {
      display: flex;
      flex-direction: column;
    }
  }

  &__heading + &__list-wrapper {
    margin-top: rem(52px);
  }
}
</style>
